import { CardManagementModel } from '@dobredary-pl/card-config';
import { Button, Grid } from '@mui/material';
import React, { createRef, useState } from 'react';
import SendLink from './SendLink';
import SendMail from './SendMail';
import SendPdf from './SendPdf';

interface CardActionsProps {
  uuid: CardManagementModel['uuid'];
  disabled?: boolean;
}

type ActionMode = 'menu' | 'link' | 'mail' | 'pdf';

const CardActions = ({ uuid, disabled }: CardActionsProps) => {
  const [mode, setMode] = useState<ActionMode>('menu');

  switch (mode) {
    case 'mail':
      return <SendMail uuid={uuid} back={() => setMode('menu')}></SendMail>;
    case 'link':
      return <SendLink uuid={uuid} back={() => setMode('menu')}></SendLink>;
    case 'pdf':
      return <SendPdf uuid={uuid} back={() => setMode('menu')}></SendPdf>;
    case 'menu':
      return (
        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Button
              {...disabled && { disabled: true }}
              variant="outlined"
              color="primary"
              onClick={() => setMode('mail')}
            >
              Wyślij e-mail
            </Button>
          </Grid> */}
          <Grid item xs={12}>
            <Button 
              {...disabled && { disabled: true }} 
              variant="outlined" 
              color="primary" 
              onClick={() => setMode('pdf')}
            >
              Pobierz plik PDF
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              {...disabled && { disabled: true }}
              variant="outlined"
              color="primary"
              onClick={() => setMode('link')}
            >
              Generuj link
            </Button>
          </Grid>
          <Grid item xs={12} hidden>
            TODO Social icons here
          </Grid>
        </Grid>
      );
  }
};

export default CardActions;