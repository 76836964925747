import { Backdrop, Box, CircularProgress } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import React from 'react';
import WizardButtons from './WizardButtons';
import WizardStepper from './WizardStepper';
import { AnySchema } from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface WizardProps {
  children: React.ReactElement[];
  stepsConfig: { key: string; prompt: string }[];
  initialValues: any;
  validationSchema: AnySchema;
  handleBack: () => void;
  handleSubmit: (values) => void;
}

const Wizard = ({
  children,
  stepsConfig,
  initialValues,
  handleBack,
  validationSchema,
  handleSubmit,
}: WizardProps) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const stepElements = React.Children.toArray(children);

  const handleStep = (stepToSet: number) => () => {
    if (stepToSet >= 0 && stepToSet < stepsConfig.length) {
      setActiveStep(stepToSet);
    }
  };

  return (
    <div>
      <Formik
        validateOnBlur
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <WizardStepper
              currentStep={activeStep}
              steps={stepsConfig.map((x) => x.prompt)}
              handleStep={handleStep}
            />
            <Box my={4}>{stepElements[activeStep]}</Box>
            <WizardButtons
              currentStep={activeStep}
              backText="Wybierz inną kartkę"
              backAction={handleBack}
              lastStepText="Przekaż darowiznę"
              totalSteps={stepsConfig.length}
              stepChanged={handleStep}
              allowNext={!errors[stepsConfig[activeStep].key]}
            />
            <Backdrop open={isSubmitting} className={classes.backdrop}>
              <CircularProgress color="primary" />
            </Backdrop>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Wizard;
