import { CardGiftModel } from '@dobredary-pl/card-config';
import React, { memo } from 'react';
import styled from 'styled-components';

const CardText = styled.section`
  overflow-x: auto;
  padding: 1em;
  height: 100%;
  footer {
    margin-top: 5em;
    font-size: 0.75em;
    color: gray;
  }
  .text {
    text-align: justify;
  }
  .signature {
    margin-top: 3em;
    text-align: right;
  }
`;

interface CardGiftTextProps {
  card: CardGiftModel;
}

const CardGiftText = memo(({ card }: CardGiftTextProps) => {
  return (
    <CardText>
      <h2>{card.cardConfig.cardHeader}</h2>
      {card.cardConfig.cardText.split('\n').map((paragraph, i) => (
        <p key={`${i}_${paragraph}`} className="text">
          {paragraph}
        </p>
      ))}
      <p className="signature">
        <em>{card.cardConfig.signature}</em>
      </p>
      {card.donationCause && card.cardConfig.showCause ? (
        <footer>{card.donationCause.selectedItemText}</footer>
      ) : (
        ''
      )}
    </CardText>
  );
});

export default CardGiftText;
