import { page } from './index.module.scss';
import CardList from '../components/card-list/CardList';
import Layout from '../components/layout';
import DonationPrompt from '../components/DonationPrompt';
import NewsletterSubscription from '../components/NewsletterSubscription';

const Index = () => {
  return (
    <Layout>
      <div className={page}>
        <main>
          <CardList />
        </main>
      </div>
      <DonationPrompt />
      <NewsletterSubscription />
    </Layout>
  );
};

export default Index;
