exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-card-gift-card-id-tsx": () => import("./../../../src/pages/card-gift/[cardId].tsx" /* webpackChunkName: "component---src-pages-card-gift-card-id-tsx" */),
  "component---src-pages-card-management-card-id-tsx": () => import("./../../../src/pages/card-management/[cardId].tsx" /* webpackChunkName: "component---src-pages-card-management-card-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-verification-card-id-tsx": () => import("./../../../src/pages/payment-verification/[cardId].tsx" /* webpackChunkName: "component---src-pages-payment-verification-card-id-tsx" */),
  "component---src-templates-card-configuration-tpl-tsx": () => import("./../../../src/templates/CardConfigurationTpl.tsx" /* webpackChunkName: "component---src-templates-card-configuration-tpl-tsx" */),
  "component---src-templates-card-details-tpl-tsx": () => import("./../../../src/templates/CardDetailsTpl.tsx" /* webpackChunkName: "component---src-templates-card-details-tpl-tsx" */)
}

