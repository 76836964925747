import { CardManagementModel } from '@dobredary-pl/card-config';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { createRef } from 'react';

interface SendLinkProps {
  uuid: CardManagementModel['uuid'];
  back: () => void;
}

const SendLink = ({ uuid, back }: SendLinkProps) => {
  const fileInput = createRef<HTMLInputElement>();

  const copyVal = () => {
    fileInput.current.select();
    fileInput.current.setSelectionRange(0, 99999);
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(fileInput.current.value);
    } else {
      document.execCommand('copy');
    }
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="back"
        onClick={() => back()}
        size="large"
      >
        <ArrowBack fontSize="small" />
      </IconButton>
      Skopiuj kod i wyślij do wybranej osoby:
      <form noValidate autoComplete="off">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs>
            <TextField
              fullWidth
              label=""
              value={`${window.location.origin}/card-gift/${uuid}`}
              inputRef={fileInput}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => copyVal()}
            >
              Kopiuj kod
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SendLink;
