import { CardManagementModel } from '@dobredary-pl/card-config';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import CardGiftViewStatic from '../../components/card-gift/CardGiftViewStatic';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { CardGiftModel } from '@dobredary-pl/card-config';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import styled from "styled-components"

interface SendPdfProps {
  uuid: CardManagementModel['uuid'];
  back: () => void;
}

const Spacer = styled.div`
  margin-bottom: 1em;
`;

const SendPdf = ({ uuid, back }: SendPdfProps) => {  
  const pdfRef = React.useRef();
  const [card, setCard] = useState<CardGiftModel | null>(null);

  useEffect(() => {
    axios
      .get<{ result: CardGiftModel }>(`${process.env.API_URL}/gift/${uuid}`)
      .then(({ data: { result: card } }) => {
        setCard(card);
      })
      .catch(() => navigate('/404'));
  }, []);

  const downloadPdf = async (card) => {
    // grab the card component from the page
    const element = pdfRef.current;

    // adjust viewport to be size we want to export the pdf to
    const viewPort = document.getElementsByName("viewport")[0];
    const viewPortOriginalContent = viewPort.content;
    viewPort.content = "width=1600";

    // put the card component from the page in an image
    const canvas = await html2canvas(element, {
      logging: false,
    });

    // revert the viewport to it's original size
    viewPort.setAttribute("content", viewPortOriginalContent);

    // put the component in an image
    const img = canvas.toDataURL('image/png', 1);

    // create a pdf
    const pdfWidth = canvas.width
    const pdfHeight = canvas.height
    const pdf = new jsPDF({
          orientation: 'l', // landscape
          unit: 'pt', // points, pixels won't work properly
          format: [pdfWidth, pdfHeight] // set needed dimensions for any element
    });

    // put the image in the pdf and download it
    pdf.addImage(img, 'PNG', 0, -(pdfHeight * 0.01), pdfWidth, pdfHeight);
    pdf.save('Twoja kartka.pdf');
  }

  return (
    <>
      <IconButton
        color="primary"
        aria-label="back"
        onClick={() => back()}
        size="large"
      >
        <ArrowBack fontSize="small" />
      </IconButton>
      <br />
      Podgląd:
      <Spacer ref={pdfRef}>
        <CardGiftViewStatic card={card} />
      </Spacer>
      <Spacer>
        Pobierz kartkę w PDF i prześlij obdarowanej osobie:
      </Spacer>
      <Spacer>
        <form noValidate autoComplete="off">
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => downloadPdf(card)}
              >
                Pobierz
              </Button>
            </Grid>
          </Grid>
        </form>
      </Spacer>
    </>
  );
};

export default SendPdf;
