import { CardManagementModel } from '@dobredary-pl/card-config';
import { Box, Grid, Link, Divider } from '@mui/material';
import React from 'react';
import CardThumbnail from '../card-details/CardThumbnail';
import Loader from '../Loader';
import CardActions from './CardActions';
import Banner from './Banner';

interface CardManagementViewParams {
  cards: CardManagementModel[];
}

const CardManagementView = ({ cards }: CardManagementViewParams) => {
  if (!cards || cards.length === 0) {
    return <Loader />;
  }

  const containsMoreThanOneCard = cards.length > 1;
  const mailToHref = `mailto:pomagam@otwarteklatki.pl`;

  return (
    <>
      <Grid item xs={12}>
        {containsMoreThanOneCard ? (
          <>
            <Box my={12}>
              Dziękujemy za zakup kartek charytatywnych i pomoc zwierzętom! <b>W ciągu 60 minut otrzymasz na swój adres e-mail link do tej strony. </b> 
              Jeśli napotkasz jakiekolwiek problemy, prosimy o kontakt z nami: <Link href={mailToHref}>pomagam@otwarteklatki.pl</Link>
            </Box>
            <Box my={12}>
              Możesz przekazać każdą ze swoich kartek dalej! Poniżej znajdziesz kilka sposobów, aby przekazać swoje podarunki odbiorcy:
            </Box>
          </>
        ) : (
          <>
            <Box my={12}>
              Dziękujemy za zakup kartki charytatywnej i pomoc zwierzętom! W ciągu 60 minut otrzymasz na swój adres e-mail link do tej strony. 
              Jeśli napotkasz jakiekolwiek problemy, prosimy o kontakt z nami: <Link href={mailToHref}>pomagam@otwarteklatki.pl</Link>
            </Box>
            <Box my={12}>
              Możesz przekazać swoją kartkę dalej! Poniżej znajdziesz kilka sposobów, aby przekazać swoje podarunki odbiorcy:
            </Box>
          </>
        )}
      </Grid>
      {cards.map((card, index) => (
        <React.Fragment key={card.uuid}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5} md={3}>
              <CardThumbnail cardId={card.cardId} />
            </Grid>
            <Grid item xs={12} sm>
              {card.wasPaid ? (
                <CardActions uuid={card.uuid} disabled={false} />
              ) : (
                <>
                  <Banner message="Wciąż oczekujemy na płatność za tę kartę. Jeśli problem będzie się powtarzał, spróbuj odświeżyć stronę. W przeciwnym razie napisz do nas e-mail na adres" email="pomagam@otwarteklatki.pl" />
                  <br />
                  <CardActions uuid={card.uuid} disabled={true}/>
                </>
              )}
            </Grid>
          </Grid>
          {index !== cards.length - 1 && (
            // Only add divider if it's not the last card
            <Divider sx={{ my: 6 }} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default CardManagementView;