import { object, string, SchemaOf, boolean, number } from 'yup';
import { CardConfigModel } from '@dobredary-pl/card-config';

export const CardConfigSchema: SchemaOf<CardConfigModel> = object().shape({
  cardId: string().required('Wybierz kartkę'),
  cardConfig: object().shape({
    cardHeader: string()
      .max(250, 'Zbyt długi tekst: użyj maksymalnie 250 znaków.')
      .required('Pole wymagane'),
    cardText: string()
      .max(1000, 'Zbyt długi tekst: użyj maksymalnie 1000 znaków.')
      .required('Pole wymagane'),
    signature: string()
      .max(250, 'Zbyt długi tekst: użyj maksymalnie 250 znaków.')
      .required('Pole wymagane'),
    showCause: boolean(),
  }),
  donorInfo: object().shape({
    name: string()
      .max(250, 'Zbyt długi tekst: użyj maksymalnie 250 znaków.')
      .required('Pole wymagane'),
    mail: string().email('Nieprawidłowy e-mail').required('Pole wymagane'),
    allowNewsletter: boolean(),
  }),
  donationCause: object().shape({
    selectedItemId: string().required('Wybierz cel darowizny'),
    selectedItemText: string().required('Wybierz tekst darowizny'),
  }),
  donationAmount: object().shape({
    selectedItemId: string().required('Wybierz kwotę darowizny'),
    amount: number()
      .typeError('Wybierz poprawną kwotę darowizny')
      .required()
      .min(0.01, 'Wybierz kwotę większą od 0'),
  }),
});
