import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { AlertTitle, Link } from '@mui/material';

interface BannerProps {
    title?: string;
    message: string;
    email?: string;
}

export default function Banner({ title, message, email }: BannerProps) {
    if (!title) title = "Informacja";
    const href = email ? `mailto:${email}` : undefined;
    return (
        <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="info">
                <AlertTitle>{title}</AlertTitle>
                {message}{' '}
                { email && (
                    <Link href={href}>
                        {email}
                    </Link>
                )}
            </Alert>
        </Stack>
    );
}