import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  InputAdornment,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { object, SchemaOf, number } from 'yup';
import { Formik, Form, useFormikContext } from 'formik';
import { CardConfigModel } from '@dobredary-pl/card-config';

const CardContainer = styled.div`
  height: 100%;
  position: relative;
  .full-height-card {
    height: 100%;
  }
  transition: box-shadow 0.3s;
  &:hover,
  &.selected {
    cursor: pointer;
    box-shadow: 2px 2px 10px 2px gray;
  }

  & .selected-circle {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

const AutoSubmit = ({ values, submitForm }) => {
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      submitForm();
    }

    isFirstRenderRef.current = false;
  }, [values, submitForm]);

  return null;
};

interface DonationAmountItemProps {
  id: string;
  title: string;
  description: string;
  min: number;
  max: number;
  onItemClick: (id: string, userEnteredAmount: number) => void;
  isSelected: boolean;
}

const DonationAmountUserProvidedItem = ({
  id,
  title,
  description,
  onItemClick,
  isSelected,
  min,
  max,
}: DonationAmountItemProps) => {
  const schema: SchemaOf<{ amount: number }> = object().shape({
    amount: number()
      .min(min, `Minimalna kwota wynosi ${min} PLN`)
      .max(max, `Maksymalna kwota wynosi ${max} PLN`)
      .test(
        'is-decimal',
        'Niepoprawna kwota',
        (val) => !val || RegExp(/^\d*([\.,]\d{1,2})?$/).test(val.toString())
      )
      .required('Pole wymagane'),
  });

  const {
    values: {
      donationAmount: { amount: initialAmount, selectedItemId },
    },
  } = useFormikContext<CardConfigModel>();

  return (
    <Grid item xs={12} sm={4} md={3} lg={3}>
      <Formik
        initialValues={{
          amount: selectedItemId === id ? initialAmount ?? min : min,
        }}
        validationSchema={schema}
        onSubmit={({ amount }) => onItemClick(id, amount)}
      >
        {({ handleBlur, handleChange, values, errors, submitForm }) => (
          <CardContainer
            onClick={() => {
              onItemClick(id, null);
              submitForm();
            }}
            className={isSelected ? 'selected' : ''}
          >
            <Card className="full-height-card">
              {isSelected ? (
                <CheckCircle className="selected-circle" color="primary" />
              ) : null}
              <CardHeader title={title}></CardHeader>
              <CardContent>
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
                <TextField
                  fullWidth
                  type="number"
                  name="amount"
                  label="Kwota"
                  placeholder="Wpisz kwotę"
                  value={values.amount}
                  onChange={(e) => {
                    onItemClick(id, null);
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(errors?.amount)}
                  helperText={errors?.amount}
                  InputProps={{
                    inputProps: { step: 5, min: min, max: max },
                    endAdornment: (
                      <InputAdornment position="end">PLN</InputAdornment>
                    ),
                  }}
                />
                <AutoSubmit values={values} submitForm={submitForm} />
              </CardContent>
            </Card>
          </CardContainer>
        )}
      </Formik>
    </Grid>
  );
};

export default DonationAmountUserProvidedItem;
