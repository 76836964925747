import React from 'react';
import { graphql, StaticQuery, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CardImageModel } from '../../models/card-image-model';
import styled from 'styled-components';

interface CardThumbnailProps {
  cardId: string;
  images: CardImageModel[];
}

const CardThumbnailContainer = styled.article`
  width: 200px;
`;

const CardThumbnailInternal = ({
  images,
  cardId,
  ...imageProps
}: CardThumbnailProps) => {
  const image = images.find(({ cardId: id }) => id === cardId);

  return (
    <GatsbyImage image={image.image} alt={image.title} title={image.title} />
  );
};

const CardThumbnail = (props: Omit<CardThumbnailProps, 'images'>) => {
  const {
    allWpCard: { nodes: allCardImages },
  } = useStaticQuery(graphql`
    query ThumbnailQuery {
      allWpCard {
        nodes {
          id
          title
          uri
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 400)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <CardThumbnailContainer>
      <CardThumbnailInternal
        images={allCardImages.map((node) => ({
          cardId: node.id,
          title: node.title,
          image:
            node.featuredImage.node.localFile.childImageSharp.gatsbyImageData,
        }))}
        {...props}
      />
    </CardThumbnailContainer>
  );
};

export default CardThumbnail;
