import { CardGiftModel } from '@dobredary-pl/card-config';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import React from 'react';
import Loader from '../Loader';
import CardFilledView from '../card-filled-view/CardFilledView';
import CardImage from '../card-details/CardImage';
import CardGiftText from './CardGiftText';

interface CardGiftViewProps {
  card: CardGiftModel;
}

const CardGiftView = ({ card }: CardGiftViewProps) => {
  const [staticViewMode, setStaticViewMode] = React.useState(false);

  const handleChange = (event) => {
    setStaticViewMode(event.target.checked);
  };

  if (!card) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {!staticViewMode ? (
          <Grid item xs={12} className="center-contents">
            <CardFilledView card={card}></CardFilledView>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={5}>
              <CardImage cardId={card.cardId} />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <CardGiftText card={card}></CardGiftText>
            </Grid>
          </>
        )}
        <Grid item xs={12} className="center-contents">
          <FormControlLabel
            control={
              <Switch
                checked={staticViewMode}
                color="primary"
                onChange={handleChange}
                name="noAnimation"
              />
            }
            label="Pokaż bez animacji"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CardGiftView;
