import React from 'react';
import { Link } from 'gatsby';

import styled from 'styled-components';

const HeaderContainer = styled.div`
  & header {
    text-align: center;

    & h1 {
      font-weight: normal;
    }

    & a {
      color: inherit;
      text-decoration: none;
    }
  }
`;

const Header = ({}) => (
  <HeaderContainer>
    <header className="flex">
      <Link to="/">
        <h1>
          Dobre<strong>dary</strong>
        </h1>
      </Link>
    </header>
  </HeaderContainer>
);

export default Header;
