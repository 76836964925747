import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link } from 'gatsby';
import CardDetailsPanel from './CardDetailsPanel';
import CardSendingOptionsPanel from './CardSendingOptionsPanel';

const CardInfoContainer = styled.div`
  h2 {
    margin-top: 0;
  }

  section {
    margin-top: 1em;
  }

  .card-tabs [role='tablist'] {
    border-bottom: 2px solid lightgray;
  }
`;

interface CardInfoParams {
  title: string;
  author: string;
  description: string;
}

const CardInfo = ({ title, author, description }: CardInfoParams) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // TODO: find a React HTML parser to avoid using dangerouslySetInnerHTML
  return (
    <CardInfoContainer>
      <h2>{title}</h2>
      <section>
        <strong>Autor: </strong> {author}
      </section>
      <section dangerouslySetInnerHTML={{ __html: description }}></section>
      <section>
        <Link to="configure" className="no-link-decoration">
          <Button variant="contained" color="primary" size="large">
            Podaruj kartkę
          </Button>
        </Link>
      </section>
      <section>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className="card-tabs"
        >
          <Tab label="Szczegóły kartki" />
          <Tab label="Dostępne opcje wysyłki" />
        </Tabs>

        <Box role="tabpanel" hidden={value !== 0}>
          <CardDetailsPanel />
        </Box>
        <Box role="tabpanel" hidden={value !== 1}>
          <CardSendingOptionsPanel />
        </Box>
      </section>
    </CardInfoContainer>
  );
};

export default CardInfo;
