import { Box, Grid } from '@mui/material';
import { Alert } from '@mui/material';
import { useFormikContext } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { CardConfigModel } from '@dobredary-pl/card-config';
import DonationAmountItem from '../DonationAmountItem';
import DonationAmountUserProvidedItem from '../DonationAmountUserProvidedItem';
import {
  WpDonationAmountPredefined,
  WpDonationAmountUserProvided,
} from '@dobredary-pl/wp-models';

const DonationAmountStep = ({}) => {
  const {
    allWpDonationAmount: { nodes: donationAmountsList },
  }: {
    allWpDonationAmount: {
      nodes: (WpDonationAmountUserProvided | WpDonationAmountPredefined)[];
    };
  } = useStaticQuery(graphql`
    query DonationAmountListQuery {
      allWpDonationAmount(
        sort: {
          fields: [
            acfDonationAmount___order
            acfDonationAmount___donationAmount
          ]
        }
      ) {
        nodes {
          id
          title
          content
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    quality: 90
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          acfDonationAmount {
            donationAmount
            isDefault
            order
            isUserProvidedAmount
            minUserProvidedAmount
            maxUserProvidedAmount
            donationCauseCustomization {
              description
              donationCause {
                ... on WpDonationCause {
                  id
                  slug
                }
              }
              fieldGroupName
              title
              featuredImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 400
                      quality: 90
                      placeholder: TRACED_SVG
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    setFieldValue,
    values: {
      donationAmount,
      donationCause: { selectedItemId: selectedDonationCauseId },
    },
    errors: { donationAmount: donationAmountErrors },
  } = useFormikContext<CardConfigModel>();

  useEffect(() => {
    const defaultItemToSelect = donationAmountsList.find(
      (node) => node.acfDonationAmount?.isDefault
    );

    if (!donationAmount?.selectedItemId && defaultItemToSelect) {
      handleItemClick(
        defaultItemToSelect.id,
        defaultItemToSelect.acfDonationAmount.donationAmount
      );
    }
  }, []);

  const [amountListToDisplay, setAmountListToDisplay] = useState([]);

  useEffect(() => {
    const getDonationCauseSpecificData = (
      donationAmount: WpDonationAmountUserProvided | WpDonationAmountPredefined,
      donationCauseId: string
    ) => {
      return donationAmount?.acfDonationAmount?.donationCauseCustomization?.find(
        (customization) => customization.donationCause?.id === donationCauseId
      );
    };

    setAmountListToDisplay(
      donationAmountsList.map((donationAmount) => {
        const specificData = getDonationCauseSpecificData(
          donationAmount,
          selectedDonationCauseId
        );

        return {
          ...donationAmount,
          title: specificData?.title ?? donationAmount.title,
          content: specificData?.description ?? donationAmount.content,
          featuredImage: (
            specificData?.featuredImage ?? donationAmount.featuredImage?.node
          )?.localFile.childImageSharp.gatsbyImageData,
        };
      })
    );
  }, [donationAmountsList, selectedDonationCauseId]);

  const handleItemClick = (id: string, amount: number) => {
    setFieldValue(
      'donationAmount',
      { selectedItemId: id, amount: amount },
      true
    );
  };

  return (
    <div>
      <Grid container spacing={3} justifyContent="center">
        {amountListToDisplay.map(
          ({
            id: donationAmountId,
            title,
            content,
            featuredImage,
            acfDonationAmount: {
              donationAmount: amount,
              isUserProvidedAmount,
              minUserProvidedAmount,
              maxUserProvidedAmount,
            },
          }) =>
            isUserProvidedAmount ? (
              <DonationAmountUserProvidedItem
                key={donationAmountId}
                id={donationAmountId}
                title={title}
                description={content}
                min={minUserProvidedAmount}
                max={maxUserProvidedAmount}
                isSelected={donationAmountId === donationAmount.selectedItemId}
                onItemClick={(id, userEnteredAmount) =>
                  handleItemClick(donationAmountId, userEnteredAmount)
                }
              />
            ) : (
              <DonationAmountItem
                key={donationAmountId}
                id={donationAmountId}
                title={title}
                image={featuredImage}
                description={content}
                isSelected={donationAmountId === donationAmount.selectedItemId}
                onClick={() => handleItemClick(donationAmountId, amount)}
              />
            )
        )}
      </Grid>
      {donationAmountErrors?.selectedItemId && (
        <Box my={2}>
          <Alert severity="error">{donationAmountErrors.selectedItemId}</Alert>
        </Box>
      )}
      {!donationAmountErrors?.selectedItemId && donationAmountErrors?.amount && (
        <Box my={2}>
          <Alert severity="error">{donationAmountErrors.amount}</Alert>
        </Box>
      )}
    </div>
  );
};

export default DonationAmountStep;
