import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  article?: boolean;
  noIndex?: boolean;
}

const SEO = ({ title, description, image, article, noIndex }: SEOProps) => {
  const { pathname } = useLocation();

  const {
    wpPage: { acfMeta: defaultMetaValues },
  } = useStaticQuery(graphql`
    query SEOPage {
      wpPage(slug: { eq: "seo" }) {
        acfMeta {
          metaTitle
          metaTitleTemplate
          metaDescription
          metaImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          metaKeywords
          metaNoIndex
        }
      }
    }
  `);

  const seo = {
    title,
    titleTemplate: `%s ${defaultMetaValues.metaTitleTemplate}`,
    description: description || defaultMetaValues.metaDescription,
    image:
      image ||
      defaultMetaValues.metaImage.localFile.childImageSharp.gatsbyImageData
        .images.fallback.src,
    url: `https://dobredary.pl${pathname}`,
    noIndex: noIndex || defaultMetaValues.metaNoIndex,
  };

  return (
    <Helmet
      title={seo.title}
      defaultTitle={defaultMetaValues.metaTitle}
      titleTemplate={seo.titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.noIndex && <meta name="robots" content="noindex"></meta>}

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default SEO;
