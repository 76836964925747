import React from 'react';

import styled from 'styled-components';

import Grid from '@mui/material/Grid';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const CardContainer = styled.div`
  height: 100%;
  position: relative;
  .full-height-card {
    height: 100%;
  }
  transition: box-shadow 0.3s;
  &:hover,
  &.selected {
    cursor: pointer;
    box-shadow: 2px 2px 10px 2px gray;
  }

  & .selected-circle {
    position: absolute;
    right: 10px;
  }
`;

interface DonationCauseItemProps {
  index: number;
  title: string;
  uri: string;
  image: any;
  description: string;
  onClick: (id) => void;
  isSelected: boolean;
}

const DonationCauseItem = ({
  index,
  title,
  uri,
  image,
  description,
  onClick,
  isSelected,
}: DonationCauseItemProps) => {
  return (
    <Grid item xs={12} sm={4} md={3} lg={3}>
      <CardContainer
        onClick={() => onClick(index)}
        className={isSelected ? 'selected' : ''}
      >
        <Card className="full-height-card">
          <CardMedia>
            <GatsbyImage image={image} title={title} alt={title} />
          </CardMedia>
          {isSelected ? (
            <CheckCircle className="selected-circle" color="primary" />
          ) : null}
          <CardHeader title={title}></CardHeader>
          <CardContent
            dangerouslySetInnerHTML={{ __html: description }}
          ></CardContent>
        </Card>
      </CardContainer>
    </Grid>
  );
};

export default DonationCauseItem;
