import React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

const CardContainer = styled.div`
  height: 100%;
  position: relative;
  .full-height-card {
    height: 100%;
  }
  transition: box-shadow 0.3s;
  &:hover,
  &.selected {
    cursor: pointer;
    box-shadow: 2px 2px 10px 2px gray;
  }

  & .selected-circle {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

interface DonationAmountItemProps {
  id: string;
  title: string;
  description: string;
  onClick: (id: string) => void;
  isSelected: boolean;
  image: IGatsbyImageData;
}

const DonationAmountItem = ({
  id,
  title,
  description,
  onClick,
  isSelected,
  image,
}: DonationAmountItemProps) => {
  return (
    <Grid item xs={12} sm={4} md={3} lg={3}>
      <CardContainer
        onClick={() => onClick(id)}
        className={isSelected ? 'selected' : ''}
      >
        <Card className="full-height-card">
          {isSelected ? (
            <CheckCircle className="selected-circle" color="primary" />
          ) : null}
          <CardHeader title={title}></CardHeader>
          {description && (
            <CardContent
              dangerouslySetInnerHTML={{ __html: description }}
            ></CardContent>
          )}
          {image && (
            <CardMedia>
              <GatsbyImage image={image} title={title} alt={title} />
            </CardMedia>
          )}
        </Card>
      </CardContainer>
    </Grid>
  );
};

export default DonationAmountItem;
