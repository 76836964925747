import React from 'react';
import { graphql, Link as GatsbyLink, navigate } from 'gatsby';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Layout from '../components/layout';
import CardInfo from '../components/card-details/CardInfo';
import CardImagePreview from '../components/card-details/CardImagePreview';
import SEO from '../components/SEO';

interface CardDetailsProps {
  data: any;
  pageContext: string;
}

const CardListItem = ({ data: { wpCard }, pageContext }: CardDetailsProps) => {
  const mockCardData = {
    cardId: wpCard.id,
    cardConfig: {
      cardHeader: 'Hej!',
      cardText:
        'To przykładowa treść kartki, którą samodzielnie uzupełnisz. To tutaj zobaczysz słowa, które wypłyną prosto z Twojego serca! \n\n Na koniec zdecydujesz, jak chcesz przekazać obdarowanemu swoją kartkę. Będziesz mieć możliwość wygenerowania unikalnego linku do kartki oraz pobrania kartki ze swoimi życzeniami w PDF.',
      signature: 'Tutaj znajdzie się Twój podpis <3',
      showCause: true,
    },
    donationCause: {
      selectedItemId: null,
      selectedItemText:
        'Na kartce może się też znaleźć cel darowizny - możesz go także ukryć',
    },
    uuid: null,
  };

  return (
    <Layout customSEO>
      <SEO title={wpCard.title} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Box m="1em">
            <Breadcrumbs aria-label="breadcrumb">
              <GatsbyLink to="/">
                <Typography color="textPrimary">eKartki</Typography>
              </GatsbyLink>
              <Typography color="textPrimary">{wpCard.title}</Typography>
            </Breadcrumbs>{' '}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md>
          <CardImagePreview
            cardData={mockCardData}
            handleSelectCard={() => navigate('configure')}
          ></CardImagePreview>
        </Grid>
        <Grid item xs={12} sm={12} md={7}>
          <CardInfo
            title={wpCard.title}
            author={wpCard.acfCard.artworkAuthor}
            description={wpCard.content}
          ></CardInfo>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CardListItem;

export const query = graphql`
  query CardDetailsQuery($id: String!) {
    wpCard(id: { eq: $id }) {
      id
      title
      content
      acfCard {
        artworkAuthor
      }
    }
  }
`;
