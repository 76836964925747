import React from 'react';

import styled from 'styled-components';

import Grid from '@mui/material/Grid';
import { Link } from 'gatsby';
import CardThumbnail from '../card-details/CardThumbnail';

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & .image-wrapper:hover {
    cursor: pointer;
    box-shadow: 2px 2px 10px 2px gray;
  }
  a {
    display: flex;
  }
`;

interface CardListItemProps {
  cardId: string;
  title: string;
  uri: string;
}

const CardListItem = ({ cardId, title, uri }: CardListItemProps) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <CardContainer>
        <Link to={uri}>
          <CardThumbnail cardId={cardId} />
        </Link>
      </CardContainer>
    </Grid>
  );
};

export default CardListItem;
