import { Button } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

type WizardButtonsProps = {
  currentStep: number;
  totalSteps: number;
  lastStepText: string;
  backText: string;
  previousStepText?: string;
  nextStepText?: string;
  stepChanged: (number) => (number) => void;
  backAction: () => void;
  allowNext: boolean;
};

const WizardButtons = ({
  currentStep,
  totalSteps,
  lastStepText,
  backText,
  stepChanged,
  backAction,
  allowNext,
  previousStepText = 'Poprzedni krok',
  nextStepText = 'Następny krok',
}: WizardButtonsProps) => {
  const { setSubmitting, isSubmitting, handleSubmit } = useFormikContext();
  return (
    <ButtonsContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={currentStep !== 0 ? stepChanged(currentStep - 1) : backAction}
      >
        {currentStep === 0 ? backText : previousStepText}
      </Button>
      {currentStep !== totalSteps - 1 ? (
        <Button
          variant="contained"
          color="primary"
          onClick={stepChanged(currentStep + 1)}
          disabled={!allowNext}
        >
          {nextStepText}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSubmitting(true);
            handleSubmit();
          }}
          disabled={!allowNext || isSubmitting}
        >
          {lastStepText}
        </Button>
      )}
    </ButtonsContainer>
  );
};

export default WizardButtons;
