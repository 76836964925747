import React, { useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import {
  createTheme,
  useTheme,
  ThemeProvider,
  Theme,
} from '@mui/material/styles';

import { object, string } from 'yup';

import { box } from './NewsletterSubscription.module.scss';
import { useFormik } from 'formik';
import { Box, Link, Typography } from '@mui/material';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidthOnSm: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
  })
);

const validationSchema = object().shape({
  name: string().required('Pole wymagane'),
  mail: string().email('Nieprawidłowy e-mail').required('Pole wymagane'),
});

interface NewsletterSubscriptionProps {}

const NewsletterSubscription = ({}: NewsletterSubscriptionProps) => {
  const parentTheme = useTheme();
  const theme = createTheme({
    ...parentTheme,
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffffff',
      },
      secondary: {
        main: '#ffffff',
      },
    },
  });

  const classes = useStyles();

  const [subscribed, setSubscribed] = useState(false);

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    submitForm,
    submitCount,
  } = useFormik({
    initialValues: {
      name: '',
      mail: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const success = await axios.post(`${process.env.API_URL}/subscribe`, {
          name: values.name,
          mail: values.mail,
        });

        setSubscribed(!!success);
      } catch (error) {}
    },
  });

  return (
    <div className={box}>
      {!subscribed ? (
        <ThemeProvider theme={theme}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={3} className={classes.fullWidthOnSm}>
              Bądz na bieżąco. <br />
              Zapisz się do newslettera!
            </Grid>
            <Grid item xs={12} sm>
              <form noValidate>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm md>
                    <Box my={4} mx={4}>
                      <TextField
                        fullWidth
                        name="name"
                        label="Twoje imię"
                        placeholder="Wpisz swoje imię i nazwisko"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          submitCount !== 0 &&
                          touched?.name &&
                          Boolean(errors?.name)
                        }
                        helperText={touched?.name && errors?.name}
                      />
                    </Box>
                    <Box my={4} mx={4}>
                      <TextField
                        fullWidth
                        type="email"
                        name="mail"
                        label="Twój adres e-mail"
                        placeholder="Wpisz swój adres e-mail"
                        value={values.mail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          submitCount !== 0 &&
                          touched?.mail &&
                          Boolean(errors?.mail)
                        }
                        helperText={touched?.mail && errors?.mail}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={5} md={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={() => submitForm()}
                    >
                      Dołącz teraz
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>

          <Typography variant="subtitle2" align="center">
            Wysyłając formularz, wyrażasz zgodę na przetwarzanie zawartych w nim
            danych osobowych i informowanie Cię o działaniach Stowarzyszenia 
            Otwarte Klatki zgodnie z naszą{' '}
            <Link
              href="https://www.otwarteklatki.pl/polityka-prywatnosci"
              target="_blank"
              rel="noreferrer"
              underline="always"
            >
              polityką prywatności
            </Link>
            .
          </Typography>
        </ThemeProvider>
      ) : (
        <>
          Dziękujemy Wojciech!
          <p>
            Na Twoja skrzynkę wysłaliśmy prośbę o potwierdzenie adresu e-mail.
            Kliknij w link załączony w wiadomości. Jeżeli nie widzisz maila z
            potwierdzeniem, sprawdź w folderze SPAM.
          </p>
        </>
      )}
    </div>
  );
};

export default NewsletterSubscription;
