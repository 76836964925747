import React from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Link } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
}));

function LoadingWheel() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      <Box my={12} mx={4}>
        Płatność oczekuje na potwierdzenie, prosimy o cierpliwość... <br /> <br />
        Jeśli nie zostaniesz przekierowany wkrótce, spróbuj odświeżyć stronę lub napisać na adres{' '}
        <Link href="mailto:pomagam@otwarteklatki.pl">
          pomagam@otwarteklatki.pl
        </Link>
        .
      </Box>
    </div>
  );
}

export default LoadingWheel;