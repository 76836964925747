import { Box, Divider, Grid } from '@mui/material';
import React from 'react';

interface CardDetailsPanelProps {}

const CardDetailsPanel = ({}: CardDetailsPanelProps) => {
  return (
    <Box my={4}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm className="center-contents center-text">
          Format A4
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} sm className="center-contents center-text">
          Możliwość dodania własnych życzeń
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} sm className="center-contents center-text">
          Możliwość wyboru celu, na który przeznaczymy darowiznę
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardDetailsPanel;
