import { CardGiftModel } from '@dobredary-pl/card-config';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import React from 'react';
import Loader from '../Loader';
import CardFilledView from '../card-filled-view/CardFilledView';
import CardImage from '../card-details/CardImage';
import CardGiftText from './CardGiftText';
import styled from 'styled-components';

interface CardGiftViewProps {
  card: CardGiftModel;
}

const CardGiftViewStatic = ({ card }: CardGiftViewProps) => {
  if (!card) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={6}>
            <CardImage cardId={card.cardId} />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <CardGiftText card={card}></CardGiftText>
          </Grid>
      </Grid>
    </>
  );
};

export default CardGiftViewStatic;
