import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ['Raleway', 'Helvetica', 'sans-serif'].join(','),
    fontSize: 14,
    subtitle2: {
      fontSize: '0.75em',
    },
  },
  spacing: 4,
  palette: {
    primary: {
      main: '#17baaa',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ba178d',
    },
  },
  components: {
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: '0.5rem',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: '0.5rem',
        },
      },
    },
  },
});

export default theme;
