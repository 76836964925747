import { CardGiftModel } from '@dobredary-pl/card-config';
import React, { useState } from 'react';
import CardImage from '../card-details/CardImage';
import {
  cardContainer,
  card as cardStyle,
  cardPanel,
  cardPanelFront,
  cardPanelBack,
  cardOpen,
  cardOpenPrompt,
} from './CardFilledView.module.scss';
import CardGiftText from '../card-gift/CardGiftText';

interface CardFilledViewProps {
  card: CardGiftModel;
}

const CardFilledView = ({ card }: CardFilledViewProps) => {
  const [open, setOpen] = useState(false);

  const rotateCard = () => {
    setOpen(!open);
  };

  return (
    <div
      className={`${cardContainer} ${open ? cardOpen : ''}`}
      onClick={() => rotateCard()}
    >
      <div className={`${cardStyle}`}>
        <div className={`${cardPanel} ${cardPanelFront}`}>
          <CardImage cardId={card.cardId}></CardImage>
        </div>
        <div className={`${cardPanel} ${cardPanelBack}`}>
          <CardGiftText card={card}></CardGiftText>
        </div>
      </div>
      <div className={cardOpenPrompt}></div>
    </div>
  );
};

export default CardFilledView;
