import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CardFilledView from '../card-filled-view/CardFilledView';
import { CardGiftModel } from '@dobredary-pl/card-config';

interface CardPreviewDialogProps {
  open: boolean;
  cardData: CardGiftModel;
  handleClose: () => void;
  handleSelectCard: () => void;
}

const CardPreviewDialog = ({
  open,
  cardData,
  handleClose,
  handleSelectCard,
}: CardPreviewDialogProps) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" scroll="body">
      <DialogTitle id="simple-dialog-title">Podgląd kartki</DialogTitle>
      <DialogContent>
        <CardFilledView card={cardData}></CardFilledView>
      </DialogContent>
      <DialogActions>
        {handleSelectCard ? (
          <Button onClick={handleSelectCard} variant="outlined" color="primary">
            Podaruj kartkę
          </Button>
        ) : (
          ''
        )}
        <Button onClick={handleClose} color="primary">
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CardPreviewDialog;
