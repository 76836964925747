import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CardListItem from './CardListItem';
import CategoriesFilter from './CategoriesFilter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: '1em',
    },
  })
);

const CardList = () => {
  const {
    allWpCard: { nodes: allCards },
  } = useStaticQuery(graphql`
    query CardListQuery {
      allWpCard(
        sort: { fields: categories___nodes___acfCategory___order, order: ASC }
        filter: { acfCard: { available: { eq: true } } }
      ) {
        nodes {
          id
          title
          uri
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
  `);

  const classes = useStyles();

  const [allowedCategories, setAllowedCategories] = useState(new Set([]));
  const [filteredList, setFilteredList] = useState(allCards);

  useEffect(() => {
    setFilteredList(
      allCards.filter(
        ({ categories: { nodes: cardCategories } }) =>
          cardCategories.some((category) =>
            allowedCategories.has(category.slug)
          ) ||
          (cardCategories.length === 0 && allowedCategories.has('inne'))
      )
    );
  }, [allowedCategories]);

  return (
    <>
      <CategoriesFilter onChange={setAllowedCategories} />
      <Grid container spacing={3} className={classes.root} alignItems="center">
        {filteredList.map(({ id, title, uri }) => {
          return (
            <CardListItem
              key={id}
              cardId={id}
              title={title}
              uri={uri}
            ></CardListItem>
          );
        })}
      </Grid>
    </>
  );
};

export default CardList;
