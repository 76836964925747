import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import styled from 'styled-components';
import Header from './Header';
import GDPRCookieBanner from './GDPRCookieBanner';
import { CookiesProvider } from 'react-cookie';
import SEO from './SEO';
import theme from '../styles/mui-theme';

const ContactFooter = styled.div`
  text-align: center;
  font-size: 0.75em;
`;

const LayoutContainer = styled.div`
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding: 1em;
`;

interface LayoutProps {
  children: React.ReactElement | React.ReactElement[];
  customSEO?: boolean;
}

const Layout = ({ children, customSEO }: LayoutProps) => (
  <CookiesProvider>
    {!customSEO && <SEO />}
    <ThemeProvider theme={theme}>
      <LayoutContainer>
        <CssBaseline />
        <Header />
        {children}
        <div className="center-contents">
          <ContactFooter key="contact-footer">
            Potrzebujesz pomocy? <br />
            Napisz do nas:{' '}
            <Link href="mailto:pomagam@otwarteklatki.pl">
              pomagam@otwarteklatki.pl
            </Link>
          </ContactFooter>
        </div>
      </LayoutContainer>
      <GDPRCookieBanner />
    </ThemeProvider>
  </CookiesProvider>
);

export default Layout;
