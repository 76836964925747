import { Box, Grid } from '@mui/material';
import { Alert } from '@mui/material';
import { useFormikContext } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import { CardConfigModel } from '@dobredary-pl/card-config';
import DonationCauseItem from '../DonationCauseItem';

const DonationCauseStep = ({}) => {
  const {
    allWpDonationCause: { nodes: allCauses },
  } = useStaticQuery(graphql`
    query DonationCauseListQuery {
      allWpDonationCause {
        nodes {
          id
          title
          uri
          content
          acfDonationCause {
            cardText
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    quality: 90
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    setFieldValue,
    values: { donationCause },
    errors: { donationCause: donationCauseErrors },
    validateForm,
  } = useFormikContext<CardConfigModel>();

  const handleItemClick = (index: string, cardText: string) => {
    setFieldValue(
      'donationCause',
      { selectedItemId: index, selectedItemText: cardText },
      true
    );
  };

  useEffect(() => {
    validateForm();
  }, []);

  return (
    <div>
      <Grid container spacing={3} justifyContent="center">
        {allCauses.map(
          ({
            id,
            title,
            featuredImage,
            uri,
            content,
            acfDonationCause: { cardText },
          }) => {
            return (
              <DonationCauseItem
                key={id}
                index={id}
                onClick={() => handleItemClick(id, cardText)}
                isSelected={id === donationCause.selectedItemId}
                title={title}
                uri={uri}
                description={content}
                image={
                  featuredImage.node.localFile.childImageSharp.gatsbyImageData
                }
              ></DonationCauseItem>
            );
          }
        )}
      </Grid>
      {donationCauseErrors?.selectedItemId && (
        <Box my={2}>
          <Alert severity="error">{donationCauseErrors.selectedItemId}</Alert>
        </Box>
      )}
    </div>
  );
};

export default DonationCauseStep;
