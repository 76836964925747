import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { CardConfigModel } from '@dobredary-pl/card-config';

const DonorInfoStep = ({}) => {
  const {
    handleBlur,
    handleChange,
    values: { donorInfo },
    errors: { donorInfo: donorInfoErrors },
    touched: { donorInfo: donorInfoTouched },
  } = useFormikContext<CardConfigModel>();

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} sm={8} md={6}>
        <Box my={4}>
          <Typography>
            Wpisz swoje dane i przejdź na stronę płatności, aby przekazać
            darowiznę. Po zakończeniu zostaniesz przekierowany do strony z twoją
            kartką.
          </Typography>
        </Box>
        <Box my={4}>
          <TextField
            fullWidth
            name="donorInfo.name"
            label="Twoje imię"
            placeholder="Wpisz swoje imię i nazwisko"
            value={donorInfo.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={donorInfoTouched?.name && Boolean(donorInfoErrors?.name)}
            helperText={donorInfoTouched?.name && donorInfoErrors?.name}
          />
        </Box>
        <Box my={4}>
          <TextField
            fullWidth
            type="email"
            name="donorInfo.mail"
            label="Twój adres e-mail"
            placeholder="Wpisz swój adres e-mail"
            value={donorInfo.mail}
            onChange={handleChange}
            onBlur={handleBlur}
            error={donorInfoTouched?.mail && Boolean(donorInfoErrors?.mail)}
            helperText={donorInfoTouched?.mail && donorInfoErrors?.mail}
          />
        </Box>
        <Box mb={2} mt={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={donorInfo.allowNewsletter}
                onChange={handleChange}
                onBlur={handleBlur}
                name="donorInfo.allowNewsletter"
                color="primary"
              />
            }
            label="Chcę dostawać informacje o działaniach Stowarzyszenia Otwarte Klatki"
          />

          <Typography variant="subtitle2" align="center">
            Zaznaczając tę opcję wyrażasz zgodę na przetwarzanie powyższych
            danych osobowych i informowanie Cię o działaniach Stowarzyszenia 
            Otwarte Klatki zgodnie z naszą{' '}
            <Link
              href="https://www.otwarteklatki.pl/polityka-prywatnosci"
              target="_blank"
              rel="noreferrer"
            >
              polityką prywatności
            </Link>
            .
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DonorInfoStep;
