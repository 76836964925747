import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import { navigate } from 'gatsby';
import { CardGiftModel } from '@dobredary-pl/card-config';
import CardGiftView from '../../components/card-gift/CardGiftView';
import SEO from '../../components/SEO';

interface CardManagementProps {
  cardId: string;
}

const CardGift = ({ cardId: id }: CardManagementProps) => {
  const [card, setCard] = useState<CardGiftModel | null>(null);

  useEffect(() => {
    axios
      .get<{ result: CardGiftModel }>(`${process.env.API_URL}/gift/${id}`)
      .then(({ data: { result: card } }) => {
        setCard(card);
      })
      .catch(() => navigate('/404'));
  }, []);

  return (
    <Layout customSEO>
      <SEO title={card?.cardConfig?.cardHeader} noIndex={true} />
      <div>
        <main>
          <CardGiftView card={card} />
        </main>
      </div>
    </Layout>
  );
};

export default CardGift;
