import React, { useEffect } from 'react';
import LoadingWheel from '../../components/payment-verification/LoadingWheel';

interface PaymentVerificationProps {
  cardId: string;
}

const PaymentVerificationPage = ({ cardId: id }: PaymentVerificationProps) => {

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      try {
        const response = await fetch(`${process.env.API_URL}/order/${id}`);
        const data = await response.json();
        if (data.result.wasPaid) {
          window.location.href = `/card-management/${id}`;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const interval = setInterval(() => {
      fetchPaymentStatus();
    }, 2000);

    return () => clearInterval(interval);

  }, [id]);

  return (<LoadingWheel />);
}

export default PaymentVerificationPage;