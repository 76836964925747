import { StaticImage } from 'gatsby-plugin-image';
import { box, text } from './DonationPrompt.module.scss';

const DonationPrompt = ({}) => (
  <div className={box + ' f'} key="donation-prompt">
    <div className="f-static">
      <StaticImage
        src="../images/ok_full_white.png"
        alt="Logo"
        placeholder="tracedSVG"
        height={60}
      />
    </div>
    <div className={text + ' f-auto'}>
      Dzięki Dobrym Darom możesz obdarować swoich bliskich szczęściem, pomagając
      dodatkowo potrzebującym zwierzętom. Twój adresat otrzyma e-kartkę i
      życzenia, a Twoja pomoc umożliwi Otwartym Klatkom skuteczniej walczyć o
      zwierzęta.
    </div>
  </div>
);

export default DonationPrompt;
