import { Step, StepButton, Stepper } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StepperContainer = styled.div`
  .MuiStepper-root {
    background-color: inherit;
  }
`;

interface WizardStepperProps {
  currentStep: number;
  steps: string[];
  handleStep: (index) => () => void;
}

const WizardStepper = ({
  currentStep,
  steps,
  handleStep,
}: WizardStepperProps) => (
  <StepperContainer>
    <Stepper alternativeLabel activeStep={currentStep}>
      {steps.map((label, index) => {
        const stepProps = { completed: false };
        const buttonProps = {};

        return (
          <Step key={label} {...stepProps}>
            <StepButton onClick={handleStep(index)} {...buttonProps}>
              {label}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  </StepperContainer>
);

export default WizardStepper;
