import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { CardConfigModel, CardGiftModel } from '@dobredary-pl/card-config';
import CardImagePreview from '../../card-details/CardImagePreview';

type CardStepProps = {
  card: { id: string };
};

const CardStep = ({ card }: CardStepProps) => {
  const {
    handleBlur,
    handleChange,
    values: { cardConfig, donationCause },
    errors: { cardConfig: cardConfigErrors },
    touched: { cardConfig: cardConfigTouched },
  } = useFormikContext<CardConfigModel>();

  const cardPreview: CardGiftModel = {
    uuid: null,
    cardId: card.id,
    cardConfig,
    donationCause,
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm md>
        <CardImagePreview cardData={cardPreview}></CardImagePreview>
      </Grid>
      <Grid item xs={12} sm={8} md={7}>
        <Box my={4}>
          <TextField
            fullWidth
            name="cardConfig.cardHeader"
            label="Treść nagłówka"
            placeholder="Wpisz własny nagłówek kartki"
            value={cardConfig.cardHeader}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              cardConfigTouched?.cardHeader &&
              Boolean(cardConfigErrors?.cardHeader)
            }
            helperText={
              cardConfigTouched?.cardHeader && cardConfigErrors?.cardHeader
            }
          />
        </Box>
        <Box my={4}>
          <TextField
            fullWidth
            multiline
            rows={4}
            name="cardConfig.cardText"
            label="Treść życzeń"
            placeholder="Napisz własny tekst życzeń"
            value={cardConfig.cardText}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              cardConfigTouched?.cardText && Boolean(cardConfigErrors?.cardText)
            }
            helperText={
              cardConfigTouched?.cardText && cardConfigErrors?.cardText
            }
          />
        </Box>
        <Box my={4}>
          <TextField
            fullWidth
            name="cardConfig.signature"
            label="Podpis"
            placeholder="Wpisz własne imię"
            value={cardConfig.signature}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              cardConfigTouched?.signature &&
              Boolean(cardConfigErrors?.signature)
            }
            helperText={
              cardConfigTouched?.signature && cardConfigErrors?.signature
            }
          />
        </Box>
        <Box my={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={cardConfig.showCause}
                onChange={handleChange}
                onBlur={handleBlur}
                name="cardConfig.showCause"
                color="primary"
              />
            }
            label="Pokaż na kartce cel, na który została przeznaczona darowizna"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CardStep;
