import { CircularProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  height: 100%;
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = ({}) => (
  <>
    <LoaderContainer>
      <CircularProgress />
    </LoaderContainer>
  </>
);

export default Loader;
