import { Box, Divider, Grid } from '@mui/material';
import { Facebook, Link, Mail, PictureAsPdf } from '@mui/icons-material';
import React from 'react';

interface CardSendingOptionsPanelProps {}

const CardSendingOptionsPanel = ({}: CardSendingOptionsPanelProps) => {
  return (
    <Box my={4}>
      <Grid container spacing={3} alignItems="center">
        {/* <Grid item xs={12} sm className="center-contents center-text">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Facebook color="primary" />
            Udostępnienie na Facebooku
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem /> */}
        <Grid item xs={12} sm className="center-contents center-text">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Link color="primary" />
            Wygenerowanie linka
          </Grid>
        </Grid>
        {/* <Divider orientation="vertical" flexItem />
        <Grid item xs={12} sm className="center-contents center-text">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Mail color="primary" />
            Wysyłka na e-mail
          </Grid>
        </Grid> */}
        <Divider orientation="vertical" flexItem />
        <Grid item xs={12} sm className="center-contents center-text">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <PictureAsPdf color="primary" />
            Kartka w PDF
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CardSendingOptionsPanel;
