import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CardImageModel } from '../../models/card-image-model';

interface CardImageProps {
  cardId: string;
  images: CardImageModel[];
  
}

const CardImage = ({ images, cardId, ...imageProps }: CardImageProps) => {
  const image = images.find(({ cardId: id }) => id === cardId);

  return (
    <>
      <GatsbyImage image={image.image} alt={image.title} title={image.title} />
    </>
  );
};

export default (props: Omit<CardImageProps, 'images'>) => (
  <StaticQuery
    query={graphql`
      query ImageQuery {
        allWpCard {
          edges {
            node {
              id
              title
              uri
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1000
                        quality: 90
                        placeholder: TRACED_SVG
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allWpCard: { edges } }) => {
      const images: CardImageModel[] = edges.map(({ node }) => ({
        cardId: node.id,
        title: node.title,
        image:
          node.featuredImage.node.localFile.childImageSharp.gatsbyImageData,
      }));

      return (
        <>
          <CardImage images={images} {...props} />
        </>
      );
    }}
  />
);
