import React, { useState } from 'react';
import CardImage from '../card-details/CardImage';

import styled from 'styled-components';
import Link from '@mui/material/Link';
import CardPreviewDialog from '../card-preview-dialog/CardPreviewDialog';
import { CardGiftModel } from '@dobredary-pl/card-config';

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & .gatsby-image-wrapper {
    &:hover {
      cursor: pointer;
      box-shadow: 2px 2px 10px 2px gray;
    }
  }
  & a {
    cursor: pointer;
  }
`;

interface CardImagePreviewProps {
  cardData?: CardGiftModel;
  handleSelectCard?: () => void;
}

const CardImagePreview = ({
  cardData,
  handleSelectCard,
  ...imageProps
}: CardImagePreviewProps) => {
  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <>
      <CardContainer onClick={() => setPreviewOpen(true)}>
        <CardImage cardId={cardData.cardId} {...imageProps}></CardImage>
        <Link>Podgląd kartki</Link>
      </CardContainer>
      <CardPreviewDialog
        open={previewOpen}
        cardData={cardData}
        handleClose={() => setPreviewOpen(false)}
        handleSelectCard={handleSelectCard}
      ></CardPreviewDialog>
    </>
  );
};

export default CardImagePreview;
