import { CardManagementModel } from '@dobredary-pl/card-config';
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Link,
  LinearProgress,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { object, SchemaOf, string } from 'yup';
import { useState } from 'react';

interface SendMailProps {
  uuid: CardManagementModel['uuid'];
  back: () => void;
}

type SendingStatus = 'success' | 'failed' | 'none';

const SendMail = ({ uuid, back }: SendMailProps) => {
  const schema: SchemaOf<{ mail: string }> = object().shape({
    mail: string().email('Nieprawidłowy e-mail').required('Pole wymagane'),
  });

  const [sendStatus, setSendStatus] = useState<SendingStatus>('none');

  const sendMail = async ({ mail }) => {
    try {
      const response = await axios.post<{ message: string }>(
        `${process.env.API_URL}/sendGift`,
        {
          uuid,
          mail,
        }
      );

      if (response?.data?.message) {
        setSendStatus('success');
      }
    } catch (error) {
      setSendStatus('failed');
      console.error(error);
    }
  };

  const handleClose = (event, reason?) => {
    if (reason === 'clickaway') {
      return;
    }

    setSendStatus('none');
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="back"
        onClick={() => back()}
        size="large"
      >
        <ArrowBack fontSize="small" />
      </IconButton>
      Do kogo chcesz wysłać kartkę?
      <Formik
        initialValues={{ mail: '' }}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          await sendMail(values);
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          handleBlur,
          handleChange,
          touched,
          values,
          errors,
        }) => (
          <>
            <Form>
              <Grid container spacing={3}>
                <Grid item xs>
                  <TextField
                    fullWidth
                    type="mail"
                    name="mail"
                    label="E-mail"
                    placeholder="Wpisz adres e-mail"
                    value={values.mail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched?.mail && Boolean(errors?.mail)}
                    helperText={touched?.mail && errors?.mail}
                  />
                  {isSubmitting && (
                    <Box mt={2}>
                      <LinearProgress />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Box mt={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Wyślij
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
            <Snackbar
              open={sendStatus === 'success'}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert onClose={handleClose} severity="success">
                Dziękujemy! Twoja kartka została wysłana.
              </Alert>
            </Snackbar>
            <Snackbar
              open={sendStatus === 'failed'}
              autoHideDuration={5000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Alert onClose={handleClose} severity="error">
                Wysyłanie wiadomości nie powiodło się! Spróbuj ponownie później
                lub napisz do nas:{' '}
                <Link href="mailto:pomagam@otwarteklatki.pl">
                  pomagam@otwarteklatki.pl
                </Link>
              </Alert>
            </Snackbar>
          </>
        )}
      </Formik>
    </>
  );
};

export default SendMail;
