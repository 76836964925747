import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';

interface ChipData {
  key: number;
  label: string;
}

const CategoriesFilterContainer = styled.div`
  & ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0.25em;
    margin: 0;

    & li {
      margin: 0.25em;
    }
  }
`;

type CategoriesFilterProps = {
  onChange: (selectedCategorySlugsSet: Set<string>) => void;
};

const CategoriesFilter = ({ onChange }: CategoriesFilterProps) => {
  const parentTheme = useTheme();
  const theme = createTheme({
    ...parentTheme,
    palette: {
      ...parentTheme.palette,
      secondary: {
        main: '#646464',
      },
    },
  });

  const {
    allWpCategory: { nodes: allCategories },
  } = useStaticQuery(graphql`
    query CategoriesQuery {
      allWpCategory(sort: { fields: acfCategory___order }) {
        nodes {
          acfCategory {
            order
          }
          name
          slug
          description
        }
      }
    }
  `);

  const [selected, setSelected] = React.useState(
    new Set<string>(allCategories.map((x) => x.slug))
  );

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const handleClick = (slug) => {
    setSelected(new Set([slug]));
  };

  const handleAllSelectedClick = () => {
    if (allCategories.length !== selected.size) {
      setSelected(new Set(allCategories.map((x) => x.slug)));
    }
  };

  const hasAllCategories = () => allCategories.length === selected.size;

  return (
    <CategoriesFilterContainer>
      <ThemeProvider theme={theme}>
        <ul>
          <li key={'all'}>
            <Button
              onClick={() => handleAllSelectedClick()}
              variant="outlined"
              color={hasAllCategories() ? 'primary' : 'secondary'}
            >
              Wszystkie
            </Button>
          </li>
          {allCategories.map(({ name, slug }) => {
            return (
              <li key={slug}>
                <Button
                  onClick={() => handleClick(slug)}
                  variant="outlined"
                  color={selected.has(slug) ? 'primary' : 'secondary'}
                >
                  {name}
                </Button>
              </li>
            );
          })}
        </ul>
      </ThemeProvider>
    </CategoriesFilterContainer>
  );
};

export default CategoriesFilter;
